<template>
    <div
        :id="blokId(blok)"
        v-editable="blok"
        :style="styles"
        class="bg-cover bg-center"
    >
        <div
            class="p-4 sm:p-6 lg:p-16 max-w-7xl mx-auto flex flex-col gap-y-4 sm:gap-y-6 lg:gap-y-16 text-center"
        >
            <div v-if="blok.title" class="h1">
                {{ blok.title }}
            </div>
            <div class="aspect-w-16 aspect-h-9">
                <iframe
                    :src="blok.url"
                    frameborder="0"
                    allow="autoplay; encrypted-media;"
                    allowfullscreen
                />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
// import ButtonContainer from '~/components/Buttons/ButtonContainer.vue'
import { blokId } from '~/utils/functions'

const props = defineProps({ blok: Object })

const styles = computed(() => {
    let style = `background-color: ${props.blok.background_color};`
    if (props.blok.background_image?.filename) {
        style =
            style +
            `background-image: url('${props.blok.background_image.filename}');`
    }
    return style
})
</script>
